<template>
  <profile-setup></profile-setup>
</template>

<script>
import ProfileSetup from "/src/components/steps/ProfileSetup.vue";
export default {
  name: "Profile",
  components: {
    ProfileSetup,
  },
};
</script>

<style></style>
